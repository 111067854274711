import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

import Core from '../Core';
import Event from '../Event';


export default class IframeController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;
        this.scene = this.core.scene;
        this.cssScene = this.core.cssScene;
        this.structure = this.core.structure;

        this.awake();

        this.event.on(this.event.UI_MENUBUTTON_CLICKED, (key) => {
            this.onMenuButtonClicked(key);
        });

        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, () => {
            this.event.trigger(this.event.REQUEST_DISABLE_CSSRENDERER);
        });

        this.event.on(this.event.TRIGGER_CSSRENDERER_DISABLED, () => {
            if (this.currentCssObj != null)
                this.currentCssObj.element.firstChild.src = '';

            this.aboutCssObj.element.firstChild.style.display = 'none';
            this.contactCssObj.element.firstChild.style.display = 'none';
            this.expertiseCssObj.element.firstChild.style.display = 'none';

            this.target = null;
            this.currentCssObj = null;
        });

        this.event.on(this.event.UI_MENU_URL_CHANGED, (url) => {
            this.changeSrc(url);
        });

        this.event.on(this.event.PAGE_LOADED, (page) => {
            this.sendCurrentLanguage();
        });
    }

    awake() {
        this.contactSrc = this.core.urlContainer.contactUrl;
        this.expertiseSrc = this.core.urlContainer.expertiseUrl;
        this.aboutSrc = this.core.urlContainer.aboutUrl;

        this.innerHTML =
            '<div>' +
            `<iframe src="${this.contactSrc}"` +
            `style="
            width: ${this.config.width}px;
            height: ${this.config.height}px;
            border-radius: 0%;
            border-width: 0;
            display: none;
            "` + '></iframe>' +
            '</div>';

        this.target = null;
        this.currentCssObj = null;
        this.contactScaleMtp = {
            pc: 0.381,
            mobile: 0.5
        };
        this.aboutScaleMtp = {
            pc: 0.386,
            mobile: 0.505
        };
        this.expertiseScaleMtp = {
            pc: 0.381,
            mobile: 0.5
        };
        this.currentScaleMtp = undefined;

        this.contactCssObj = this.createCSS3DObject(this.innerHTML);
        //this.contactCssObj.element.firstChild.src = this.contactSrc;
        this.cssScene.add(this.contactCssObj);

        this.expertiseCssObj = this.createCSS3DObject(this.innerHTML);
        //this.expertiseCssObj.element.firstChild.src = this.expertiseSrc;
        this.cssScene.add(this.expertiseCssObj);

        this.aboutCssObj = this.createCSS3DObject(this.innerHTML);
        //this.aboutCssObj.element.firstChild.src = this.aboutSrc;
        this.cssScene.add(this.aboutCssObj);
    }

    update() {
        if (this.target == null || this.currentCssObj == null)
            return;

        const pos = new THREE.Vector3();
        this.target.getWorldPosition(pos);
        this.currentCssObj.position.set(pos.x, pos.y, pos.z);

        const quat = new THREE.Quaternion();
        this.target.getWorldQuaternion(quat);
        this.currentCssObj.setRotationFromQuaternion(quat);

        const scale = new THREE.Vector3();
        this.structure.group.getWorldScale(scale);
        //this.currentCssObj.scale.setScalar(scale.x * this.currentScaleMtp);       // TEST-241224      스케일 조절 할 필요 없어보여서 임시로 주석
    }

    resize() {
        if (this.currentCssObj == null)
            return;

        this.currentCssObj.element.firstChild.style.width = `${this.config.width}px`;
        this.currentCssObj.element.firstChild.style.height = `${this.config.height}px`;
    }

    onMenuButtonClicked(key) {
        this.target = this.getFollowTarget(key);
        this.event.trigger(this.event.REQUEST_ENABLE_CSSRENDERER);

        if (key === 'about') {
            this.currentCssObj = this.aboutCssObj;
            this.currentScaleMtp = this.config.isMobile ? this.aboutScaleMtp.mobile : this.aboutScaleMtp.pc;

            this.changeSrc(this.aboutSrc);
            this.aboutCssObj.element.firstChild.style.display = 'block';
        }
        else if (key === 'contact') {
            this.currentCssObj = this.contactCssObj;
            this.currentScaleMtp = this.config.isMobile ? this.contactScaleMtp.mobile : this.contactScaleMtp.pc;

            this.changeSrc(this.contactSrc);
            this.contactCssObj.element.firstChild.style.display = 'block';
        }
        else if (key === 'expertise') {
            this.currentCssObj = this.expertiseCssObj;
            this.currentScaleMtp = this.config.isMobile ? this.expertiseScaleMtp.mobile : this.expertiseScaleMtp.pc;

            this.changeSrc(this.expertiseSrc);
            this.expertiseCssObj.element.firstChild.style.display = 'block';
        }

        this.resize();
    }

    createCSS3DObject(content) {
        var wrapper = document.createElement('div');
        wrapper.innerHTML = content;
        return new CSS3DObject(wrapper.firstChild);
    }

    getFollowTarget(key) {
        switch (key) {
            case 'contact': return this.structure.contactPanel.group;
            case 'expertise': return this.structure.expertisePanel.group;
            case 'about': return this.structure.aboutPanel.group;
            default: return null;
        }
    }

    changeSrc(newSrc) {
        if (this.currentCssObj == null)
            return;

        this.currentCssObj.element.firstChild.src = '';
        this.currentCssObj.element.firstChild.src = newSrc;
        setTimeout(() => {
            this.sendCurrentLanguage();
        }, 200);
    }

    sendCurrentLanguage() {
        if (this.currentCssObj == null) {
            return;
        }
        const currentLanguage = this.core.language.getCurrentLanguage();
        const msg = {
            key: 'LANGUAGE',
            value: currentLanguage
        };
        this.currentCssObj.element.firstChild.contentWindow.postMessage(msg, '*');
    }
}