import * as THREE from 'three';

import Core from '../Core';
import Event from '../Event';


export default class IframeMenuUI2D {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.awake();

        this.event.on(this.event.PAGE_LOADED, (page) => {
            this.sendLanguageMessage();
        });
        this.event.on(this.event.REQUEST_PROJECTLIST_IFRAME_ENABLE, () => {
            this.enableIframe();
        });
        this.event.on(this.event.REQUEST_PROJECTLIST_IFRAME_DISABLE, () => {
            this.disableIframe();
        });

        // NOTE-240604      iframe 내부 메뉴가 클릭되었을 때
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.changeSrc(this.aboutSrc);
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.changeSrc(this.contactSrc);
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.changeSrc(this.expertiseSrc);
        });
        this.event.on(this.event.MENU2D_URL_CHANGED, (url) => {
            this.changeSrc(url);
        });
        // //
    }

    awake() {
        this.iframe = document.getElementById('menuUI2D-iframe');
        // this.iframe.onload = () => {
        //     this.sendLanguageMessage();
        // };
        this.iframeContainer = document.getElementById('menuUI2D-iframe-container');

        this.projectListSrc = this.core.urlContainer.projectListUrl;
        this.aboutSrc = this.core.urlContainer.aboutUrl;
        this.contactSrc = this.core.urlContainer.contactUrl;
        this.expertiseSrc = this.core.urlContainer.expertiseUrl;

        this.isActive = false;
    }

    enableIframe() {
        this.iframeContainer.classList.add('show');
        this.iframe.classList.add('show');
        this.changeSrc(this.projectListSrc);
        this.isActive = true;
    }

    disableIframe() {
        this.iframeContainer.classList.remove('show');
        this.iframe.classList.remove('show');
        this.isActive = false;
        setTimeout(() => {
            this.changeSrc('');
        }, 200);
    }

    changeSrc(newSrc) {
        this.iframe.src = newSrc;
        setTimeout(() => {
            this.sendLanguageMessage();
        }, 500);
    }

    sendLanguageMessage() {
        if (this.isActive === false)
            return;

        const currentLanguage = this.core.language.getCurrentLanguage();
        const msg = {
            key: 'LANGUAGE',
            value: currentLanguage
        };
        this.iframe.contentWindow.postMessage(msg, '*');
    }
}