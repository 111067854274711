import Core from './Core';
import Event from './Event';


export default class LanguageController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.awake();

        this.event.on(this.event.LANGUAGE, (lang) => {
            this.changeLanguage(lang);
        });
    }

    awake() {
        this.languageArr = ['KOREAN', 'ENGLISH'];
        this.currentIndex = 0;
        this.changeLanguage(this.getCurrentLanguage());
    }

    saveLanguage() {
        localStorage.setItem('LANGUAGE', this.getCurrentLanguage());
    }

    changeLanguage(lang) {
        const prevLanguage = this.currentIndex;
        for (let i = 0; i < this.languageArr.length; ++i) {
            if (this.languageArr[i] === lang) {
                this.currentIndex = i;
                break;
            }
        }

        if (prevLanguage != this.currentIndex) {
            localStorage.setItem('LANGUAGE', this.getCurrentLanguage());
            this.event.trigger(this.event.LANGUAGE_CHANGED, [this.getCurrentLanguage()]);
        }
    }

    getCurrentLanguage() {
        return this.languageArr[this.currentIndex];
    }
}