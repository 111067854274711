import Core from '../Core';
import Event from '../Event';
import LogoIconHandler from './LogoIconHandler';


export default class LogoHeader {
    constructor(_options) {
        this.event = new Event();
        this.core = new Core();
        this.camera = this.core.camera;
        this.structure = this.core.structure;

        this.logoIconHandler = new LogoIconHandler();

        this.awake();

        // NOTE-240703      실행 초반 부분
        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            if (this.core.jumpToProject === false)
                this.logoText.classList.add('show');
        });
        // //

        // NOTE-240703      텍스트 로고 클릭시
        this.event.on(this.event.LOGOTEXT_CLICKED, () => {
            // NOTE-240705      이것도 원래라면 Wrapper로 감싸야겠지만 귀찮ㅇ..
            if (this.core.stateController.currentMenu === 'LISTVIEW')
                this.event.trigger(this.event.UI_SWITCHBACKBUTTON_CLICKED_WRAPPER);
            else
                this.resetView();
        });
        // //

        // NOTE-240703      메뉴 버튼 관련
        this.event.on(this.event.UI_MENUBUTTON_CLICKED, () => {
            this.logoText.classList.remove('show');
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, () => {
            this.logoText.classList.add('show');
        });
        // //

        // NOTE-240703      리스트뷰 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            this.logoText.classList.add('show');
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED, () => {
            this.logoText.classList.add('show');
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU2D, () => {
            this.logoText.classList.add('show');
        });
        // //

        // NOTE-240703      리스트뷰에서 메뉴들 눌렀을 때
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.logoText.classList.remove('show');
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.logoText.classList.remove('show');
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.logoText.classList.remove('show');
        });
        this.event.on(this.event.MENU2D_PROJECT_CLICKED, () => {
            this.logoText.classList.remove('show');
        });
        // //

        // NOTE-240704      프로젝트 상세 페이지 관련
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, () => {
            this.logoText.classList.remove('show');
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.logoText.classList.add('show');
        });
        // //
    }

    awake() {
        // NOTE-240422      logoText 초기화
        this.logoText = document.getElementById('logo-text');
        this.logoText.onclick = () => {
            this.event.trigger(this.event.LOGOTEXT_CLICKED);
        };
        this.logoText.onmouseover = () => {
            this.event.trigger(this.event.LOGOTEXT_HOVERED);
        };
        this.logoText.onmouseout = () => {
            this.event.trigger(this.event.LOGOTEXT_HOVEROUTED);
        };
    }

    resetView() {
        this.camera.resetToPerspective(0.5);
        this.structure.customOrbitControls.resetToInit();
    }
}