import * as THREE from 'three';
import gsap from 'gsap';

import Event from '../Event';
import Core from '../Core';


export default class MenuButtonHandler {
    constructor(_options) {
        this.event = new Event();
        this.core = new Core();
        this.structure = this.core.structure;
        this.camera = this.core.camera;

        this.awake();

        // NOTE-240704      메뉴 버튼 관련
        this.event.on(this.event.UI_MENUBUTTON_CLICKED, () => {
            this.onMenuButtonClicked();
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, () => {
            this.onMenuBackButtonClicked();
        });
        this.event.on(this.event.UI_MENUBUTTON_HOVERED, (key) => {
            this.onHoverWithKey(key);
        });
        this.event.on(this.event.UI_MENUBUTTON_HOVEROUTED, (key) => {
            this.onHoverOutWithKey(key);
        });
        // //

        // NOTE-240704      리스트뷰 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU, () => {
            this.menuBackButton.classList.remove('show');
        });
        // //

        // NOTE-240704      프로젝트 상세 페이지 관련
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, () => {
            this.menuBackButton.classList.add('show');
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.menuBackButton.classList.remove('show');
        });
        // //
    }

    awake() {
        this.menuUI = document.getElementById('menuUI');

        this.buttons = [];
        const normalButtons = document.getElementsByClassName('menuUI-button');
        for (let i = 0; i < normalButtons.length; ++i) {
            const key = normalButtons[i].getAttribute('key');

            normalButtons[i].onclick = () => {
                this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, [key]);
            };
            normalButtons[i].onmouseover = () => {
                this.onHover(normalButtons[i]);
                this.event.trigger(this.event.UI_MENULINE_HOVERED, [key]);
                this.event.trigger(this.event.UI_MENUTEXT_HOVERED, [key]);
            };
            normalButtons[i].onmouseout = () => {
                this.onHoverOut(normalButtons[i]);
                this.event.trigger(this.event.UI_MENULINE_HOVEROUTED, [key]);
                this.event.trigger(this.event.UI_MENUTEXT_HOVEROUTED, [key]);
            };
            this.buttons.push(normalButtons[i]);
        }
        const contactButtons = document.getElementsByClassName('menuUI-contactButton');
        for (let i = 0; i < contactButtons.length; ++i) {
            const key = contactButtons[i].getAttribute('key');

            contactButtons[i].onclick = () => {
                this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, [key]);
            };
            contactButtons[i].onmouseover = () => {
                this.onHover(contactButtons[i]);
                this.event.trigger(this.event.UI_MENULINE_HOVERED, [key]);
                this.event.trigger(this.event.UI_MENUTEXT_HOVERED, [key]);
            };
            contactButtons[i].onmouseout = () => {
                this.onHoverOut(contactButtons[i]);
                this.event.trigger(this.event.UI_MENULINE_HOVEROUTED, [key]);
                this.event.trigger(this.event.UI_MENUTEXT_HOVEROUTED, [key]);
            };
            this.buttons.push(contactButtons[i]);
        }

        // NOTE-240422      메뉴 back버튼 관련
        this.menuBackButton = document.getElementById('menu-backButton');
        this.menuBackButton.onclick = () => {
            this.event.trigger(this.event.UI_MENUBACKBUTTON_CLICKED_WRAPPER);
        };

        // NOTE-240422      원래는 logoText꺼를 사용하면 안되고 나눠야하지만, 현재로썬 그게 더 복잡하기에 공유함
        this.menuBackButton.onmouseover = () => {
            this.event.trigger(this.event.LOGOTEXT_HOVERED);
        };
        this.menuBackButton.onmouseout = () => {
            this.event.trigger(this.event.LOGOTEXT_HOVEROUTED);
        };
        // //
    }

    onMenuButtonClicked() {
        this.menuUI.classList.add('hide');
        this.menuBackButton.classList.add('show');
    }

    onMenuBackButtonClicked() {
        this.menuUI.classList.remove('hide');
        this.menuBackButton.classList.remove('show');
    }

    onHover(obj) {
        obj.classList.add('hover');
    }

    onHoverWithKey(key) {
        for (let i = 0; i < this.buttons.length; ++i) {
            const thisKey = this.buttons[i].getAttribute('key');
            if (thisKey === key) {
                this.onHover(this.buttons[i]);
                break;
            }
        }
    }

    onHoverOut(obj) {
        obj.classList.remove('hover');
    }

    onHoverOutWithKey(key) {
        for (let i = 0; i < this.buttons.length; ++i) {
            const thisKey = this.buttons[i].getAttribute('key');
            if (thisKey === key) {
                this.onHoverOut(this.buttons[i]);
                break;
            }
        }
    }
} 