import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core.js';
import Event from '../Event.js';
import GeometryUtils from '../GeometryUtils.js';

import { MeshLine, MeshLineMaterial } from 'three.meshline';


export default class ProjectItemPanel {
    constructor(_options) {
        this.structure = _options.structure;

        this.core = new Core();
        this.event = new Event();
        this.camera = this.core.camera;
        this.textureLoader = this.core.textureLoader;
        this.layer = this.core.layer;
        this.geometryUtils = new GeometryUtils();

        this.awake();

        this.event.on(this.event.PROJECT_DATA_RECEIVED, (dataList) => {
            this.projectDataList = dataList;
            this.projectCount = this.projectDataList.kor.length;
        });

        this.event.on(this.event.INIT_LOAD_STARTED, () => {
            this.loadImages();
        });

        this.event.on(this.event.PROJECTITEM_CLICKED, (params) => {
            this.event.trigger(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, [params.url]);
        });

        this.event.on(this.event.REQUEST_DISABLE_CLOSE_LINES, () => {
            this.disableCloseLinesFromCamera(8);
        });

        this.event.on(this.event.REQUEST_ENABLE_ALL_LINES, () => {
            this.resetLinesVisible();
        });
    }

    awake() {
        this.group = new THREE.Group();
        this.structure.group.add(this.group);

        this.projectCircles = [];
        this.projectLineCircles = [];
        this.projectDataList = {};
        this.projectParams = [];
        this.originTextureArr = [];

        // NOTE-240701      채도 효과 관련
        this.hoverTextureArr = [];

        this.hoverCircleDefaultScale = 1.85;

        this.lineOpacity = 0.1;
        this.lineWidth = 0.015;
        this.circleRadius = 5;
        this.circleSegment = 128;

        this.projectCount = undefined;
        this.projectCircleRadius = 0.25 * this.circleRadius;
        this.projectRingRadius = 1.4 * this.circleRadius;
        this.tailMargin = 0.12;
        this.ringAnimationTime = 1.75;

        this.initOpacities = [];

        this.initGroupRotation = {
            x: THREE.MathUtils.degToRad(-45),
            y: THREE.MathUtils.degToRad(180),
            z: THREE.MathUtils.degToRad(0)
        };
    }

    loadImages() {
        for (let i = 0; i < this.projectCount; ++i) {
            let url = this.projectDataList.kor[i].url;

            let fileName = this.projectDataList.kor[i].imageData;

            const rootUrl = window.location.host;
            let path = `https://${rootUrl}${fileName}`;
            if (this.core.isLocal) {
                fileName = fileName.replace('/uploads/', '');
                path = `${this.core.rootFilePath}/${fileName}`;
            }

            this.textureLoader.load(path, (loadedImage) => {
                loadedImage.colorSpace = THREE.SRGBColorSpace;
                this.projectParams.push({
                    index: i,
                    image: loadedImage,
                    url: url,
                    title: this.projectDataList.kor[i].title
                });

                if (this.projectParams.length === this.projectCount)
                    this.onLastLoaded();
            });
        }
    }

    onLastLoaded() {
        const sortedArr = [];
        for (let i = 0; i < this.projectParams.length; ++i) {
            for (let m = 0; m < this.projectParams.length; ++m) {
                if (this.projectParams[m].index === i)
                    sortedArr.push(this.projectParams[m]);
            }
        }
        this.projectParams = sortedArr;
        this.draw();
    }

    draw() {
        this.group.rotation.set(
            this.initGroupRotation.x,
            this.initGroupRotation.y,
            this.initGroupRotation.z
        );

        const projectCircleGeom = new THREE.CircleGeometry(
            this.projectCircleRadius,
            this.circleSegment
        );

        const startOpacityValue = 100;
        const endOpacityValue = 10;
        const commonOpacityDiff = (endOpacityValue - startOpacityValue) / (this.projectCount * 0.5 - 1);

        // NOTE-240220      프로젝트 이미지 원 생성
        for (let i = 0; i < this.projectCount; ++i) {
            const circleMat = new THREE.MeshBasicMaterial({
                map: this.projectParams[i].image,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0
            });
            this.originTextureArr.push(this.projectParams[i].image);
            this.hoverTextureArr.push(this.createNewTexture(this.projectParams[i].image, 2));

            let opacity = 1;
            if (i >= this.projectCount * 0.5) {
                const targetOpacity = startOpacityValue + commonOpacityDiff * (i - this.projectCount * 0.5);
                opacity = targetOpacity * 0.01;
            }
            this.initOpacities.push(opacity);

            const projectCircle = new THREE.Mesh(projectCircleGeom, circleMat);
            this.summitRaycastCallback(i, projectCircle);
            this.group.add(projectCircle);
            this.projectCircles.push(projectCircle);
            projectCircle.title = `${this.projectParams[i].title}`;
            projectCircle.url = this.projectParams[i].url;
        }
        // //

        // NOTE-240220      프로젝트 라인 원 생성
        const lineGeomTemp = this.geometryUtils.getCircleGeometry(
            this.projectCircleRadius,
            this.circleSegment
        );
        const lineGeom = new MeshLine();
        lineGeom.setGeometry(lineGeomTemp);
        const lineMat = new MeshLineMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0,
            lineWidth: this.lineWidth
        });
        for (let i = 0; i < this.projectCount; ++i) {
            const projectLineCircle = new THREE.Mesh(lineGeom, lineMat);
            projectLineCircle.visible = false;
            this.group.add(projectLineCircle);
            this.projectLineCircles.push(projectLineCircle);
        }
        // //

        // NOTE-240422      호버 이미지
        this.hoverDefaultOpacity = 0.7;
        const path = `${this.core.rootFilePath}/images/hover.png`;
        this.textureLoader.load(path,
            (texture) => {
                const hoverMat = new THREE.MeshBasicMaterial({
                    map: texture,
                    side: THREE.DoubleSide,
                    transparent: true,
                    opacity: 0
                });
                this.hoverCircle = new THREE.Mesh(projectCircleGeom, hoverMat);
                this.hoverCircle.position.set(0, -1000, 0);
                this.hoverCircle.scale.setScalar(this.hoverCircleDefaultScale);
                this.hoverCircle.renderOrder = 100;
                this.group.add(this.hoverCircle);
            });
        // //

        // NOTE-240207      이미지를 한번에 많이 그리면 끊켜서 꼼수 부림
        setTimeout(() => {
            this.group.visible = false;
        }, 100);
        // //
    }

    playItemRing(timeValue) {
        const time = timeValue === undefined ? this.ringAnimationTime : timeValue;
        if (this.itemRingTimeline)
            this.itemRingTimeline.pause();

        const animObj = {
            totalAngle: 0,
            zAngle: 0
        };

        if (time <= 0) {
            this.group.visible = true;
            for (let i = 0; i < this.projectCircles.length; ++i) {
                this.projectCircles[i].material.opacity = this.initOpacities[i];
            }
            this.group.rotation.z = this.initGroupRotation.z;
            for (let i = 0; i < this.projectCircles.length; ++i) {
                const item = this.projectCircles[i];
                const positions = this.getItemPositions(Math.PI * 2, i);
                item.position.set(positions.currentPos.x, positions.currentPos.y, positions.currentPos.z);
                item.lookAt(positions.nextPos);
                item.rotation.y += THREE.MathUtils.degToRad(180);

                this.projectLineCircles[i].position.set(item.position.x, item.position.y, item.position.z);
                this.projectLineCircles[i].rotation.set(item.rotation.x, item.rotation.y, item.rotation.z);
            }
        }
        else {
            this.itemRingTimeline = gsap.timeline();
            this.itemRingTimeline.to({ dummy: 0 }, {
                dummy: 0,
                duration: 0.2,
                onStart: () => {
                    this.group.visible = true;
                }
            });
            this.itemRingTimeline.fromTo(animObj, {
                totalAngle: 0,
                zAngle: this.initGroupRotation.z - THREE.MathUtils.degToRad(60)
            }, {
                totalAngle: Math.PI * 2,
                zAngle: this.initGroupRotation.z,
                duration: time,
                ease: 'power1.out',
                onStart: () => {
                    for (let i = 0; i < this.projectCircles.length; ++i) {
                        this.projectCircles[i].material.opacity = this.initOpacities[i];
                    }
                },
                onUpdate: () => {
                    this.group.rotation.z = animObj.zAngle;
                    for (let i = 0; i < this.projectCircles.length; ++i) {
                        const item = this.projectCircles[i];
                        const positions = this.getItemPositions(animObj.totalAngle, i);
                        item.position.set(positions.currentPos.x, positions.currentPos.y, positions.currentPos.z);
                        item.lookAt(positions.nextPos);
                        item.rotation.y += THREE.MathUtils.degToRad(180);

                        this.projectLineCircles[i].position.set(item.position.x, item.position.y, item.position.z);
                        this.projectLineCircles[i].rotation.set(item.rotation.x, item.rotation.y, item.rotation.z);
                    }
                }
            });
        }
    }

    showContent(timeValue) {
        const time = timeValue === undefined ? 0.5 : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const animObj = { opacity: 0 };

        if (time <= 0) {
            for (let i = 0; i < this.projectCircles.length; ++i) {
                this.projectCircles[i].visible = true;
                this.projectCircles[i].material.opacity = this.initOpacities[i];
            }
        }
        else {
            this.contentAnim = gsap.to(animObj, {
                opacity: 1,
                duration: time,
                onStart: () => {
                    for (let i = 0; i < this.projectCircles.length; ++i)
                        this.projectCircles[i].visible = true;
                },
                onUpdate: () => {
                    for (let i = 0; i < this.projectCircles.length; ++i)
                        this.projectCircles[i].material.opacity = this.initOpacities[i] * animObj.opacity;
                }
            });
        }
    }

    hideContent(timeValue) {
        const time = timeValue === undefined ? 0.5 : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const animObj = { opacity: 1 };

        if (time <= 0) {
            for (let i = 0; i < this.projectCircles.length; ++i) {
                this.projectCircles[i].material.opacity = 0;
                this.projectCircles[i].visible = false;
            }
        }
        else {
            this.contentAnim = gsap.to(animObj, {
                opacity: 0,
                duration: time,
                onUpdate: () => {
                    for (let i = 0; i < this.projectCircles.length; ++i)
                        this.projectCircles[i].material.opacity = this.initOpacities[i] * animObj.opacity;
                },
                onComplete: () => {
                    for (let i = 0; i < this.projectCircles.length; ++i)
                        this.projectCircles[i].visible = false;
                }
            });
        }
    }

    showOutline(timeValue) {
        const time = timeValue === undefined ? 0.5 : timeValue;
        if (this.outlineAnim)
            this.outlineAnim.pause();

        const animObj = { opacity: 0 };

        if (time <= 0) {
            for (let i = 0; i < this.projectLineCircles.length; ++i) {
                this.projectLineCircles[i].visible = true;
                this.projectLineCircles[i].material.opacity = this.lineOpacity;
            }
        }
        else {
            this.outlineAnim = gsap.to(animObj, {
                opacity: this.lineOpacity,
                duration: time,
                onStart: () => {
                    for (let i = 0; i < this.projectLineCircles.length; ++i)
                        this.projectLineCircles[i].visible = true;
                },
                onUpdate: () => {
                    for (let i = 0; i < this.projectLineCircles.length; ++i)
                        this.projectLineCircles[i].material.opacity = animObj.opacity;
                }
            });
        }
    }

    hideOutline(timeValue) {
        const time = timeValue === undefined ? 0.5 : timeValue;
        if (this.outlineAnim)
            this.outlineAnim.pause();

        const animObj = { opacity: this.lineOpacity };

        if (time <= 0) {
            for (let i = 0; i < this.projectLineCircles.length; ++i) {
                this.projectLineCircles[i].visible = false;
                this.projectLineCircles[i].material.opacity = 0;
            }
        }
        else {
            this.outlineAnim = gsap.to(animObj, {
                opacity: 0,
                duration: time,
                onUpdate: () => {
                    for (let i = 0; i < this.projectLineCircles.length; ++i)
                        this.projectLineCircles[i].material.opacity = animObj.opacity;
                },
                onComplete: () => {
                    for (let i = 0; i < this.projectLineCircles.length; ++i)
                        this.projectLineCircles[i].visible = false;
                }
            });
        }
    }

    getItemPositions(totalAngle, index) {
        totalAngle = Math.max(0.1, totalAngle);
        const projectCount = this.projectCount;
        const projectRingRadius = this.projectRingRadius;
        const lastMargin = Math.round(projectCount * this.tailMargin);

        const value = totalAngle / (projectCount + lastMargin) * index;
        const x = Math.cos(value) * projectRingRadius;
        const y = Math.sin(value) * projectRingRadius;

        const valueNext = totalAngle / (projectCount + lastMargin) * (index + 1);
        const xNext = Math.cos(valueNext) * projectRingRadius;
        const yNext = Math.sin(valueNext) * projectRingRadius;

        return {
            currentPos: new THREE.Vector3(x, y, 0),
            nextPos: this.group.localToWorld(new THREE.Vector3(xNext, yNext, 0))
        };
    }

    summitRaycastCallback(index, target) {
        target.layers.enable(this.layer.raycastTarget);

        target.onhover = function () {
            this.changeTargetOpacity(true);
            this.changeTargetScale(target, true);
            this.projectCircles[index].material.opacity = 1;
            this.projectCircles[index].material.map = this.hoverTextureArr[index];

            this.event.trigger(this.event.PROJECTITEM_HOVERED, [target]);
        }.bind(this);

        target.onhoverOut = function () {
            this.changeTargetOpacity(false);
            this.changeTargetScale(target, false);
            this.hoverCircle.position.set(0, -1000, 0);
            this.projectCircles[index].material.opacity = this.initOpacities[index];
            this.projectCircles[index].material.map = this.originTextureArr[index];

            this.event.trigger(this.event.PROJECTITEM_HOVEROUTED);
        }.bind(this);

        target.onclick = function () {
            this.event.trigger(this.event.PROJECTITEM_CLICKED_WRAPPER, [target.title]);
        }.bind(this);
    }

    alignFor2D(customTime) {
        if (this.align2DAnim)
            this.align2DAnim.pause();

        if (this.align2DFadeAnim)
            this.align2DFadeAnim.pause();

        const time = customTime === undefined ? 0.6 : customTime;

        const animObj = {
            totalAngle: 0,
            zAngle: 0
        };

        this.align2DAnim = gsap.fromTo(animObj, {
            totalAngle: Math.PI * 2,
            zAngle: this.initGroupRotation.z
        }, {
            totalAngle: 0,
            zAngle: this.initGroupRotation.z - THREE.MathUtils.degToRad(60),
            duration: time,
            ease: 'power1.inOut',
            onUpdate: () => {
                this.group.rotation.z = animObj.zAngle;
                for (let i = 0; i < this.projectCircles.length; ++i) {
                    const item = this.projectCircles[i];
                    const positions = this.getItemPositions(animObj.totalAngle, i);
                    item.position.set(positions.currentPos.x, positions.currentPos.y, positions.currentPos.z);
                    item.lookAt(positions.nextPos);

                    this.projectLineCircles[i].position.set(item.position.x, item.position.y, item.position.z);
                    this.projectLineCircles[i].rotation.set(item.rotation.x, item.rotation.y, item.rotation.z);
                }
            }
        });

        const fadeObj = { mtpValue: 1 };
        this.align2DFadeAnim = gsap.to(fadeObj, {
            mtpValue: 0,
            duration: time,
            onUpdate: () => {
                for (let i = 0; i < this.projectCircles.length; ++i) {
                    this.projectCircles[i].material.opacity = this.initOpacities[i] * fadeObj.mtpValue;
                }
            }
        });
    }

    disableCloseLinesFromCamera(minDist) {
        for (let i = 0; i < this.projectLineCircles.length; ++i) {
            const line = this.projectLineCircles[i];
            const lineWorldPos = this.group.localToWorld(line.position.clone());
            const dist = lineWorldPos.distanceTo(this.camera.instance.position);
            line.visible = dist >= minDist;
        }
    }

    resetLinesVisible() {
        for (let i = 0; i < this.projectLineCircles.length; ++i) {
            const line = this.projectLineCircles[i];
            line.visible = true;
        }
    }

    changeTargetOpacity(isActive) {
        if (this.hoverOpacityAnim && this.hoverOpacityAnim.isActive())
            this.hoverOpacityAnim.pause();

        this.hoverOpacityAnim = gsap.to(this.hoverCircle.material, {
            opacity: isActive ? this.hoverDefaultOpacity : 0,
            duration: 0.2,
        });
    }

    createNewTexture(originalTexture, saturationMtp) {
        const color = new THREE.Color();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = originalTexture.image.width;
        canvas.height = originalTexture.image.height;
        context.drawImage(originalTexture.image, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            color.setRGB(data[i] / 255, data[i + 1] / 255, data[i + 2] / 255);

            const hslObj = { h: 0, s: 0, l: 0 };
            color.getHSL(hslObj);
            color.setHSL(hslObj.h, hslObj.s * saturationMtp, hslObj.l);

            const rgbObj = { r: 0, g: 0, b: 0 };
            color.getRGB(rgbObj);
            data[i] = Math.floor(rgbObj.r * 255);
            data[i + 1] = Math.floor(rgbObj.g * 255);
            data[i + 2] = Math.floor(rgbObj.b * 255);
        }
        context.putImageData(imageData, 0, 0);
        const newTexture = new THREE.Texture(canvas);
        newTexture.needsUpdate = true;
        return newTexture;
    }

    changeTargetScale(target, isHover) {
        if (isHover) {
            const targetScale = 1.1;
            this.hoverScaleAnim = gsap.to(target.scale, {
                x: targetScale,
                y: targetScale,
                z: targetScale,
                duration: 0.1,
                onComplete: () => {
                    this.hoverCircle.scale.setScalar(this.hoverCircleDefaultScale * targetScale);
                    this.hoverCircle.position.set(target.position.x, target.position.y, target.position.z);
                    this.hoverCircle.rotation.set(target.rotation.x, target.rotation.y, target.rotation.z);
                }
            });
        }
        else {
            if (this.hoverScaleAnim)
                this.hoverScaleAnim.pause();

            target.scale.setScalar(1);
            this.hoverCircle.scale.setScalar(this.hoverCircleDefaultScale);
        }
    }
}