import * as THREE from 'three';
import gsap from 'gsap';

import Event from "../Event";
import Core from "../Core";


export default class LogoIconHandler {
    constructor(_options) {
        this.event = new Event();
        this.core = new Core();
        this.structure = this.core.structure;
        this.camera = this.core.camera;

        this.awake();

        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            if (this.core.jumpToProject === false)
                this.logoIcon.classList.add('show');
        });

        // NOTE-240702      리스트뷰 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            this.onSwitchButtonClick();
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED, () => {
            this.onReturnButtonClick();
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU, () => {
            this.menuUI2D.classList.add('show');
            this.logoIcon.classList.remove('show');
            this.logoIcon.classList.add('closeShow');
            this.switchBackButton.classList.add('show');
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU2D, () => {
            this.logoIcon.classList.remove('show');
            this.logoIcon.classList.add('closeShow');
            this.switchBackButton.classList.remove('show');
        });
        // //

        // NOTE-240703      리스트뷰를 타고 메뉴를 눌렀을 때
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.logoIcon.classList.add('show');
            this.logoIcon.classList.remove('closeShow');
            this.switchBackButton.classList.add('show');
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.logoIcon.classList.add('show');
            this.logoIcon.classList.remove('closeShow');
            this.switchBackButton.classList.add('show');
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.logoIcon.classList.add('show');
            this.logoIcon.classList.remove('closeShow');
            this.switchBackButton.classList.add('show');
        });
        this.event.on(this.event.MENU2D_PROJECT_CLICKED, () => {
            this.logoIcon.classList.remove('closeShow');
            this.switchBackButton.classList.add('show');
        });
        // //

        // NOTE-240704      프로젝트 상세 페이지 관련
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, () => {
            this.logoIcon.classList.remove('show');
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.logoIcon.classList.add('show');
        });
        // //
    }

    awake() {
        this.logoIcon = document.getElementById('logo-icon');
        this.logoIcon.onclick = () => {
            if (this.logoIcon.classList.contains('closeShow'))
                this.event.trigger(this.event.UI_SWITCHBACKBUTTON_CLICKED_WRAPPER);
            else if (this.logoIcon.classList.contains('show'))
                this.event.trigger(this.event.UI_SWITCHBUTTON_CLICKED_WRAPPER);
        };
        this.logoIcon.onmouseover = () => {
            this.event.trigger(this.event.UI_SWITCHBUTTON_HOVERED);
        };
        this.logoIcon.onmouseout = () => {
            this.event.trigger(this.event.UI_SWITCHBUTTON_HOVEROUTED);
        };

        this.menuUI = document.getElementById('menuUI');
        this.menuUI2D = document.getElementById('menuUI2D');
        this.switchBackButton = document.getElementById('menuUI2D-backToMain');
        this.switchBackButton.onclick = () => {
            this.event.trigger(this.event.UI_SWITCHBACKBUTTON_CLICKED_WRAPPER);
        };
    }

    onSwitchButtonClick() {
        this.menuUI.classList.add('hide');
        this.logoIcon.classList.remove('show');
        this.logoIcon.classList.add('closeShow');
        this.event.trigger(this.event.REQUEST_PROJECTLIST_IFRAME_ENABLE);

        if (this.logoIconBlockAnim)
            this.logoIconBlockAnim.pause();
        const animObj = { timer: 0 };
        let trigger = false;
        this.logoIconBlockAnim = gsap.to(animObj, {
            timer: 1,
            duration: 1,
            onStart: () => {
                this.logoIcon.style.pointerEvents = 'none';
            },
            onUpdate: () => {
                if (animObj.timer > 0.5 && trigger === false) {
                    trigger = true;
                    this.menuUI2D.classList.add('show');
                }
            },
            onComplete: () => {
                this.logoIcon.style.pointerEvents = 'initial';
            }
        });
    }

    onReturnButtonClick() {
        this.menuUI.classList.remove('hide');
        this.menuUI2D.classList.remove('show');
        this.logoIcon.classList.add('show');
        this.logoIcon.classList.remove('closeShow');
        this.switchBackButton.classList.remove('show');
        this.event.trigger(this.event.REQUEST_PROJECTLIST_IFRAME_DISABLE);

        if (this.logoIconBlockAnim)
            this.logoIconBlockAnim.pause();
        this.logoIconBlockAnim = gsap.to({ dummy: 0 }, {
            dummy: 0,
            duration: 1,
            onStart: () => {
                this.logoIcon.style.pointerEvents = 'none';
            },
            onComplete: () => {
                this.logoIcon.style.pointerEvents = 'initial';
            }
        });
    }
}