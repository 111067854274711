import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core.js';
import Event from '../Event.js';
import PanelBase from './PanelBase.js';


// NOTE-240207      Contact을 담고 있음
export default class ContactPanel extends PanelBase {
    constructor(_options) {
        super(_options);

        this.structure = _options.structure;

        this.core = new Core();
        this.textureLoader = this.core.textureLoader;
        this.config = this.core.config;
        this.debug = this.core.debug;
        this.layer = this.core.layer;
        this.event = new Event();

        this.awake();

        this.event.on(this.event.INIT_LOAD_STARTED, () => {
            this.load();
        });

        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            this.adjustMenuButtonPos();
        });

        this.event.on(this.event.UI_MENULINE_HOVERED, (name) => {
            if (name == 'contact')
                this.contentCircle.onhover();
        });
        this.event.on(this.event.UI_MENULINE_HOVEROUTED, (name) => {
            if (name == 'contact')
                this.contentCircle.onhoverOut();
        });
    }

    awake() {
        this.initOpacity = 0.5;
        this.panelTexture = undefined;

        this.buttonPosObj = new THREE.Mesh(new THREE.BoxGeometry(0.1, 0.1, 0.1), new THREE.MeshBasicMaterial());
        this.buttonPosObj.position.set(0, -7.2, 0);
        this.buttonPosObj.visible = false;
        this.group.add(this.buttonPosObj);
    }

    load() {
        const path = `${this.core.rootFilePath}/images/expertise_thumnail.png`;
        this.textureLoader.load(path,
            (texture) => {
                texture.colorSpace = THREE.SRGBColorSpace;
                this.panelTexture = texture;
                this.draw();
            }
        );
    }

    draw() {
        const mat = new THREE.MeshBasicMaterial({
            map: this.panelTexture,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: this.initOpacity
        });
        const geom = new THREE.CircleGeometry(
            this.mainCircleRadius,
            this.mainCircleSegment
        );

        this.contentCircle = new THREE.Mesh(geom, mat);
        this.contentCircle.visible = false;
        this.contentCircle.renderOrder = 9;
        this.summitRaycastCallback(this.contentCircle);
        this.group.add(this.contentCircle);
    }

    update() {
        this.event.trigger(this.event.UI_MENUBUTTON_MOVED, ['contact',
            this.group.localToWorld(this.buttonPosObj.position.clone())]
        );
    }

    showContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const animObj = {
            opacity: this.contentCircle.material.opacity
        };

        if (time <= 0) {
            this.contentCircle.layers.enable(this.layer.raycastTarget);
            this.contentCircle.visible = true;
            this.contentCircle.material.opacity = this.initOpacity;
        }
        else {
            this.contentAnim = gsap.to(animObj,
                {
                    opacity: this.initOpacity,
                    duration: time,
                    ease: this.defaultEase,
                    onStart: () => {
                        this.contentCircle.layers.enable(this.layer.raycastTarget);
                        this.contentCircle.visible = true;
                    },
                    onUpdate: () => {
                        this.contentCircle.material.opacity = animObj.opacity;
                    }
                }
            );
        }
    }

    hideContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const animObj = {
            opacity: 0
        };

        if (time <= 0) {
            this.contentCircle.layers.disable(this.layer.raycastTarget);
            this.contentCircle.material.opacity = 0;
            this.contentCircle.visible = false;
        }
        else {
            this.contentAnim = gsap.to(animObj,
                {
                    opacity: 0,
                    duration: time,
                    ease: this.defaultEase,
                    onStart: () => {
                        this.contentCircle.layers.disable(this.layer.raycastTarget);
                    },
                    onUpdate: () => {
                        this.contentCircle.material.opacity = animObj.opacity;
                    },
                    onComplete: () => {
                        this.contentCircle.visible = false;
                    }
                }
            );
        }
    }

    adjustMenuButtonPos() {
        const newButtonPos = this.group.worldToLocal(this.buttonPosObj.position.clone());
        this.buttonPosObj.position.set(newButtonPos.x, newButtonPos.y, newButtonPos.z);
    }

    summitRaycastCallback(target) {
        target.onhover = function () {
            this.changeTargetOpacity(target, 1);
        }.bind(this);

        target.onhoverOut = function () {
            this.changeTargetOpacity(target, this.initOpacity);
        }.bind(this);

        target.onclick = function () {
            this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, ['contact']);
        }.bind(this);
    }

    changeTargetOpacity(target, targetOpacity) {
        if (this.hoverAnim)
            this.hoverAnim.pause();

        this.hoverAnim = gsap.to(target.material, {
            opacity: targetOpacity,
            duration: 0.2
        });
    }
}