import Core from './Core';

export default class URLContainer {
    constructor(_options) {
        this.core = new Core();

        this.awake();
    }

    awake() {
        let rootUrl = window.location.host;
        if (this.core.isLocal)
            rootUrl = 'dev.ninefive.org'; //'www.ninefive.com'; 

        this.contactUrl = `https://${rootUrl}/view/legacy/page/contact.html`;
        this.expertiseUrl = `https://${rootUrl}/view/page/expertise.html`;
        this.aboutUrl = `https://${rootUrl}/view/legacy/page/about.html`;
        this.projectListUrl = `https://${rootUrl}/view/page/projectList.html`;
    }
}