import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core';
import Event from '../Event';


export default class BgmController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;

        this.awake();

        // NOTE-240611      끄고 키는 부분
        this.event.on(this.event.KV_CLOSED, () => {
            if (this.config.isMobile === false
                && this.core.jumpToProject === false
                && this.core.jumpToMenu === false)
                this.play();
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            if (this.isFirstCall) {
                this.isFirstCall = false;
                if (this.config.isMobile === false && this.core.jumpToProject) {
                    this.play();
                }
            }
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, () => {
            if (this.isFirstCall) {
                this.isFirstCall = false;
                if (this.config.isMobile === false && this.core.jumpToMenu) {
                    this.play();
                }
            }
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU, () => {
            if (this.isFirstCall) {
                this.isFirstCall = false;
                if (this.config.isMobile === false && this.core.jumpToMenu) {
                    this.play();
                }
            }
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            if (this.config.isMobile)
                this.hide();
            else
                this.show();
        });
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.hide();
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.hide();
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.hide();
        });
        this.event.on(this.event.MENU2D_PROJECT_CLICKED, () => {
            this.hide();
        });
        this.event.on(this.event.MENU2D_URL_CHANGED, (url) => {
            if (url === this.core.urlContainer.projectListUrl) {
                if (this.config.isMobile)
                    this.hide();
                else
                    this.show();
            }
        });
        // //

        // NOTE-240614      영상이 재생되어서 볼륨을 조절하는 부분
        this.event.on(this.event.EXPERTISE_VIDEO_PLAY, () => {
            if (this.isBgmActive)
                this.smoothSetVolume(0);
        });
        this.event.on(this.event.EXPERTISE_VIDEO_STOP, () => {
            if (this.isBgmActive)
                this.smoothSetVolume(this.defaultVolume);
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED_END, (key) => {
            if (key === 'expertise' && this.isBgmActive) {
                this.smoothSetVolume(this.defaultVolume);
            }
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED_END, () => {
            if (this.isBgmActive) {
                this.smoothSetVolume(this.defaultVolume);
            }
        });
        this.event.on(this.event.MENU2D_URL_CHANGED, (url) => {
            if (url === this.core.urlContainer.projectListUrl && this.isBgmActive) {
                this.smoothSetVolume(this.defaultVolume);
            }
        });
        // //

        this.event.on(this.event.TRIGGER_RESPONSIVE, (isMobile) => {
            if (isMobile) {
                this.hide();
                this.stop();
            }
            else {
                this.show();
            }
        });
    }

    awake() {
        this.isFirstCall = true;

        this.bgmElement = document.getElementById('bgmAudio');
        this.bgmButton = document.getElementById('menuUI2D-bgmButton');
        this.bgmButton.onclick = () => {
            if (this.isBgmActive)
                this.stop();
            else
                this.play();
        };

        this.isBgmActive = true;
        this.defaultVolume = 0.3;

        this.setVolume(this.defaultVolume);

        if (this.config.isMobile) {
            this.hide();
            this.stop();
        }
    }

    show() {
        this.bgmButton.classList.add('show');
    }

    hide() {
        this.bgmButton.classList.remove('show');
    }

    play() {
        if (this.bgmButton.classList.contains('show') === false)
            this.show();

        this.bgmElement.play();
        this.isBgmActive = true;
        this.bgmButton.classList.remove('off');
    }

    stop() {
        this.bgmElement.pause();
        this.bgmElement.currentTime = 0;
        this.isBgmActive = false;
        this.bgmButton.classList.add('off');
    }

    setVolume(value) {
        this.bgmElement.volume = value;
    }

    smoothSetVolume(value) {
        if (this.volumeAnim)
            this.volumeAnim.pause();

        this.volumeAnim = gsap.to(this.bgmElement, {
            volume: value,
            duration: 0.5
        });
    }
}