import EventEmitter from "./EventEmitter";

export default class Event extends EventEmitter {
    static instance;

    constructor(_options) {
        if (Event.instance)
            return Event.instance;

        super();

        Event.instance = this;

        this.awake();
    }

    awake() {
        // NOTE-240703      실행 초반 부분
        this.PROJECT_DATA_RECEIVED = 'PROJECT_DATA_RECEIVED';
        this.URL_CHECK_FINISHED = 'URL_CHECK_FINISHED';
        this.INIT_LOAD_STARTED = 'INIT_LOAD_STARTED';
        this.INIT_LOAD_PROGRESS = 'INIT_LOAD_PROGRESS';
        this.INIT_LOAD_FINISHED = 'INIT_LOAD_FINISHED';
        this.INTRO_STARTED = 'INTRO_STARTED';
        this.INTRO_FINISHED = 'INTRO_FINISHED';
        this.STRUCTURE_MOTION_FINISHED = 'STRUCTURE_MOTION_FINISHED';
        this.KV_CLOSED = 'KV_CLOSED';
        // //

        // NOTE-241231      iframe 페이지들이 로드되었을 때
        this.PAGE_LOADED = 'PAGE_LOADED';
        // //

        this.TRIGGER_AUTOROTATE_STOP = 'TRIGGER_AUTOROTATE_STOP';

        // NOTE-240703      메뉴 버튼 관련
        this.UI_MENUBUTTON_CLICKED_WRAPPER = 'UI_MENUBUTTON_CLICKED_WRAPPER';
        this.UI_MENUBUTTON_CLICKED = 'UI_MENUBUTTON_CLICKED';
        this.UI_MENUBACKBUTTON_CLICKED_WRAPPER = 'UI_MENUBACKBUTTON_CLICKED_WRAPPER';
        this.UI_MENUBACKBUTTON_CLICKED = 'UI_MENUBACKBUTTON_CLICKED';
        this.UI_MENUBACKBUTTON_CLICKED_END = 'UI_MENUBACKBUTTON_CLICKED_END';
        this.UI_MENU_URL_CHANGED = 'UI_MENU_URL_CHANGED';

        // NOTE-240703      메뉴 관련 기타 이벤트
        this.UI_MENUBUTTON_MOVED = 'UI_MENUBUTTON_MOVED';
        this.UI_MENUBUTTON_HOVERED = 'UI_MENUBUTTON_HOVERED';
        this.UI_MENUBUTTON_HOVEROUTED = 'UI_MENUBUTTON_HOVEROUTED';
        this.UI_MENULINE_HOVERED = 'UI_MENULINE_HOVERED';
        this.UI_MENULINE_HOVEROUTED = 'UI_MENULINE_HOVEROUTED';
        this.UI_MENUTEXT_HOVERED = 'UI_MENUTEXT_HOVERED';
        this.UI_MENUTEXT_HOVEROUTED = 'UI_MENUTEXT_HOVEROUTED';

        // NOTE-240408      프로젝트 리스트뷰(2D) 관련
        this.UI_SWITCHBUTTON_CLICKED_WRAPPER = 'UI_SWITCHBUTTON_CLICKED_WRAPPER';
        this.UI_SWITCHBUTTON_CLICKED = 'UI_SWITCHBUTTON_CLICKED';
        this.UI_SWITCHBUTTON_CLICKED_END = 'UI_SWITCHBUTTON_CLICKED_END';
        this.UI_SWITCHBUTTON_HOVERED = 'UI_SWITCHBUTTON_HOVERED';
        this.UI_SWITCHBUTTON_HOVEROUTED = 'UI_SWITCHBUTTON_HOVEROUTED';
        this.UI_SWITCHBACKBUTTON_CLICKED_WRAPPER = 'UI_SWITCHBACKBUTTON_CLICKED_WRAPPER';
        this.UI_SWITCHBACKBUTTON_CLICKED = 'UI_SWITCHBACKBUTTON_CLICKED';
        this.UI_SWITCHBACKBUTTON_CLICKED_END = 'UI_SWITCHBACKBUTTON_CLICKED_END';
        this.UI_SWITCHBUTTON_CLICKED_FROM_MENU = 'UI_SWITCHBUTTON_CLICKED_FROM_MENU';
        this.UI_SWITCHBUTTON_CLICKED_FROM_MENU2D = 'UI_SWITCHBUTTON_CLICKED_FROM_MENU2D';

        // NOTE-240703      리스트뷰 관련 기타 이벤트
        this.REQUEST_PROJECTLIST_IFRAME_ENABLE = 'REQUEST_PROJECTLIST_IFRAME_ENABLE';
        this.REQUEST_PROJECTLIST_IFRAME_DISABLE = 'REQUEST_PROJECTLIST_IFRAME_DISABLE';
        this.MENU2D_ABOUT_CLICKED = 'MENU2D_ABOUT_CLICKED';
        this.MENU2D_EXPERTISE_CLICKED = 'MENU2D_EXPERTISE_CLICKED';
        this.MENU2D_CONTACT_CLICKED = 'MENU2D_CONTACT_CLICKED';
        this.MENU2D_PROJECT_CLICKED = 'MENU2D_PROJECT_CLICKED';
        this.MENU2D_URL_CHANGED = 'MENU2D_URL_CHANGED';

        // NOTE-240703      좌측 상단 로고 관련
        this.LOGOTEXT_CLICKED = 'LOGOTEXT_CLICKED';
        this.LOGOTEXT_HOVERED = 'LOGOTEXT_HOVERED';
        this.LOGOTEXT_HOVEROUTED = 'LOGOTEXT_HOVEROUTED';

        // NOTE-240227      CSS Renderer Enable/Disable
        this.REQUEST_ENABLE_CSSRENDERER = 'REQUEST_ENABLE_CSSRENDERER';
        this.REQUEST_DISABLE_CSSRENDERER = 'REQUEST_DISABLE_CSSRENDERER';
        this.TRIGGER_CSSRENDERER_DISABLED = 'TRIGGER_CSSRENDERER_DISABLED';

        // NOTE-240703      구체의 프로젝트 링 이벤트 관련
        this.PROJECTITEM_HOVERED = 'PROJECTITEM_HOVERED';
        this.PROJECTITEM_HOVEROUTED = 'PROJECTITEM_HOVEROUTED';
        this.PROJECTITEM_CLICKED_WRAPPER = 'PROJECTITEM_CLICKED_WRAPPER';
        this.PROJECTITEM_CLICKED = 'PROJECTITEM_CLICKED';
        this.REQUEST_DISABLE_CLOSE_LINES = 'REQUEST_DISABLE_CLOSE_LINES';
        this.REQUEST_ENABLE_ALL_LINES = 'REQUEST_ENABLE_ALL_LINES';

        // NOTE-240408      프로젝트 상세 관련
        this.REQUEST_PROJECTDETAIL_IFRAME_ENABLE = 'REQUEST_PROJECTDETAIL_IFRAME_ENABLE';
        this.REQUEST_PROJECTDETAIL_IFRAME_DISABLE = 'REQUEST_PROJECTDETAIL_IFRAME_DISABLE';
        this.REQUEST_PROJECTDETAIL_URL_CHANGED = 'REQUEST_PROJECTDETAIL_URL_CHANGED';

        // NOTE-240315      Raycast ON/OFF
        this.REQUEST_RAYCAST_ENABLE = 'REQUEST_RAYCAST_ENABLE';
        this.REQUEST_RAYCAST_DISABLE = 'REQUEST_RAYCAST_DISABLE';

        // NOTE-240516      반응형 이벤트
        this.TRIGGER_RESPONSIVE = 'TRIGGER_RESPONSIVE';

        // NOTE-240604      iframe 메세지 관련
        this.RECEIVED_IFRAME_MESSAGE = 'RECEIVED_IFRAME_MESSAGE';
        this.EXPERTISE_VIDEO_PLAY = 'EXPERTISE_VIDEO_PLAY';
        this.EXPERTISE_VIDEO_STOP = 'EXPERTISE_VIDEO_STOP';

        // NOTE-241217      언어 관련
        this.LANGUAGE = 'LANGUAGE';
        this.LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';

        // NOTE-241226      MD단에서 Contact Us로 가려고 할 때
        this.GO_TO_CONTACT = 'GO_TO_CONTACT';
    }
}