import Core from './Core';
import Event from './Event';

export default class MessageHandler {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        window.addEventListener('message', (event) => {
            if (this.core.debugEnabled)
                console.log(`ReceivedMessage:\nkey: ${event.data.key}`);

            this.event.trigger(this.event.RECEIVED_IFRAME_MESSAGE, [event.data]);
        });

        this.event.on(this.event.RECEIVED_IFRAME_MESSAGE, (data) => {
            this.handleEvent(data);
        });
    }

    handleEvent(data) {
        if (data.key == undefined)
            return;

        if (data.key === 'MENU2D_ABOUT') {
            this.event.trigger(this.event.MENU2D_ABOUT_CLICKED);
        }
        else if (data.key === 'MENU2D_EXPERTISE') {
            this.event.trigger(this.event.MENU2D_EXPERTISE_CLICKED);
        }
        else if (data.key === 'MENU2D_CONTACT') {
            this.event.trigger(this.event.MENU2D_CONTACT_CLICKED);
        }
        else if (data.key === 'MENU2D_PROJECT') {
            this.event.trigger(this.event.MENU2D_PROJECT_CLICKED, [data.value]);
        }
        else if (data.key === 'EXPERTISE_VIDEO_PLAY') {
            this.event.trigger(this.event.EXPERTISE_VIDEO_PLAY);
        }
        else if (data.key === 'EXPERTISE_VIDEO_STOP') {
            this.event.trigger(this.event.EXPERTISE_VIDEO_STOP);
        }
        else if (data.key === 'LANGUAGE') {
            this.event.trigger(this.event.LANGUAGE, [data.value]);
        }
        else if (data.key === 'GO_TO_CONTACT') {
            this.event.trigger(this.event.GO_TO_CONTACT, [data.page]);
        }
        else if (data.key === 'PAGE_LOADED') {
            this.event.trigger(this.event.PAGE_LOADED, [data.page]);
        }
    }
}