import Core from './Core';
import Event from './Event';


export default class DataRequestController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.awake();
    }

    awake() {
        this.projectListData = {};
        this.sendProjectListRequest('KOREAN', (response) => this.onReceiveKor(response));
        this.sendProjectListRequest('ENGLISH', (response) => this.onReceiveEng(response));
    }

    onReceiveKor(response) {
        console.log('project list data received - KOR');
        this.projectListData.kor = response.data;

        this.onReceiveFinished();
    }

    onReceiveEng(response) {
        console.log('project list data received - ENG');
        this.projectListData.eng = response.data;

        this.onReceiveFinished();
    }

    onReceiveFinished() {
        if (this.projectListData.kor != undefined && this.projectListData.eng != undefined) {
            console.log('project list data received');
            this.event.trigger(this.event.PROJECT_DATA_RECEIVED, [this.projectListData]);
        }
    }

    sendProjectListRequest(lang, onReceived) {
        let url = `https://${window.location.host}/api/v1/main/findAll/${lang}`;
        if (this.core.isLocal)
            url = `https://dev.ninefive.org/api/v1/main/findAll/${lang}`;

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                var response = JSON.parse(xhr.responseText);

                if (onReceived != undefined)
                    onReceived(response);
            }
        };
        xhr.send();
    }
}