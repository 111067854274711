import * as THREE from 'three';
import gsap from 'gsap';

import Core from './Core';
import Event from './Event';


export default class BackgroundParticle {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.scene = this.core.scene;
        this.textureLoader = this.core.textureLoader;
        this.structure = this.core.structure;

        this.awake();

        this.event.on(this.event.INIT_LOAD_STARTED, () => {
            this.load();
        });
        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            this.show();
        });
    }

    awake() {
        this.group = new THREE.Group();
        this.scene.add(this.group);

        this.clock = new THREE.Clock();
        this.particleCount = 300;
        this.particleSize = 0.075;
        this.particleRadius = 60;
        this.previousTime = 0;

        this.alphaStartArr = [];
        for (let i = 0; i < this.particleCount; ++i) {
            this.alphaStartArr.push(
                this.random(0, Math.PI)
            );
        }
    }


    update() {
        let elapsedTime = this.clock.getElapsedTime();
        let deltaTime = elapsedTime - this.previousTime;
        this.previousTime = elapsedTime;

        if (this.particle == undefined)
            return;

        const colors = this.particle.geometry.attributes.color;
        for (let i = 0; i < colors.count; ++i) {
            colors.setW(i, Math.abs(Math.sin(this.alphaStartArr[i] + elapsedTime)));
        }
        colors.needsUpdate = true;
    }

    load() {
        const path = `${this.core.rootFilePath}/images/glow.png`;
        this.textureLoader.load(path,
            (texture) => {
                this.onloaded(texture);
            });
    }

    onloaded(texture) {
        this.glowImg = texture;
        const positions = new Float32Array(this.particleCount * 3);
        const colors = new Float32Array(this.particleCount * 4);
        for (let i = 0; i < this.particleCount; ++i) {
            positions[i * 3 + 0] = (Math.random() - 0.5) * this.particleRadius * 1.920;

            //positions[i * 3 + 1] = (Math.random() - 0.5) * this.particleRadius;
            positions[i * 3 + 1] = this.customRandom() * this.particleRadius * 1.080;

            //positions[i * 3 + 2] = (Math.random() - 0.5) * this.particleRadius;
            positions[i * 3 + 2] = 0;

            colors[i * 4 + 0] = 1;
            colors[i * 4 + 1] = 1;
            colors[i * 4 + 2] = 1;
            colors[i * 4 + 3] = 1;
        }

        const particleGeom = new THREE.BufferGeometry();
        particleGeom.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        particleGeom.setAttribute('color', new THREE.BufferAttribute(colors, 4));

        const particleMat = new THREE.PointsMaterial({
            // map: this.glowImg,
            sizeAttenuation: true,
            size: this.particleSize,
            transparent: true,
            alphaMap: this.glowImg,
            alphaTest: 0.2,
            opacity: 0,
            vertexColors: true
        });

        this.particle = new THREE.Points(particleGeom, particleMat);
        this.particle.position.set(0, 0, -5);
        this.particle.visible = false;
        this.group.add(this.particle);
    }

    show() {
        if (this.showAnim && this.showAnim.isActive())
            this.showAnim.pause();

        this.showAnim = gsap.to(this.particle.material, {
            opacity: 0.8,
            duration: 1,
            onStart: () => {
                this.particle.visible = true;
            }
        });
    }

    customRandom() {
        const randomValue = Math.sin(this.random(0, Math.PI)) * 0.5;
        const gap = Math.abs(randomValue - 0.5);
        return Math.random() > 0.5 ? gap : -gap;
    }

    random(min, max) {
        return Math.random() * (max - min) + min;
    }
}